<style lang="less" scoped>
	img {
		width: 100%;
		height: 100%;
	}

	.login_box {
		height: calc(100vh - 250px);
		height: 100vh;
		width: 100%;

		// min-height: 1500px;
		.img_box {
			position: absolute;
			top: 0;
			z-index: -1;
			width: 100%;
			height: 500px;
			background: url("../../assets/img/login/img_0_1.png") no-repeat;
		}

		// .login_bj {
		//   width: 100%;
		//   height: 500px;
		// }
		.login_box_box {
			padding-top: 150px;
			box-sizing: border-box;
		}

		.login_container {
			position: relative;
			display: flex;
			justify-content: space-between;
			// position: fixed;
			// top: 15%;
			// left: 50%;
			// transform: translate(-50%);
			z-index: 35;
			margin: 0 auto;
			margin-bottom: 72px;
			width: 1050px;
			height: 415px;
			background: #ffffff;
			box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
			border-radius: 10px;
			padding: 30px 40px;
			box-sizing: border-box;

			.back {
				position: absolute;
				top: 20px;
				left: 20px;
				width: 20px;
				height: 20px;
				cursor: pointer;
			}

			.login_left {
				width: 46%;
				height: 250px;
				text-align: center;
				margin-top: 40px;
				border-right: 2px solid #eeeeee;

				img {
					width: 320px;
					height: 128px;
					margin-top: 60px;
				}
			}

			/deep/ .login_fight .el-form-item__content {
				// position: relative;
				margin-left: 0 !important;
				width: 395px;
			}

			/deep/ .login_fight .el-input__inner {
				border: none;
				height: 50px;
				padding-left: 35px;
				background-color: #f2f2f2;
			}

			/deep/ .login_fight .el-input .el-input__clear {
				font-size: 20px;
			}

			/deep/.yzm_box .el-form-item__content {
				width: 100%;
				display: flex;
				// justify-content: space-between;
			}

			.yzm_box {
				.el-input {
					width: 260px;
				}

				.img_yz {
					width: 120px;
					height: 50px;
					background: #f2f2f2;
					border-radius: 6px;
					margin-left: 20px;
					cursor: pointer;
				}

				.yzm {
					border: none;
				}
			}

			.login_fight {
				width: 46%;
				// background-color: pink;
				padding: 0 25px;
				box-sizing: border-box;

				.safe {
					width: 20px;
					height: 20px;
					position: absolute;
					top: 15px;
					left: 0;
					z-index: 22;
					margin: 0 10px;
				}

				.yzm {
					width: 136px;
					height: 50px;
					line-height: 50px;
					background: #f2f2f2;
					border-radius: 6px;
					font-size: 14px;
					color: #0090f0;
					text-align: center;
					margin-left: 20px;
					cursor: pointer;
				}

				.login_tit {
					font-size: 22px;
					font-weight: 700;
					text-align: center;
					margin-bottom: 20px;
				}

				.login_btm {
					display: flex;
					justify-content: space-between;
					width: 100%;
					font-size: 12px;
					color: #666;
					margin-bottom: 20px;

					span {
						color: #0090f0;
						cursor: pointer;
					}

					>div:nth-child(2) {
						cursor: pointer;
					}
				}

				.login_btn {
					width: 395px;
					height: 50px;
					line-height: 50px;
					background: #0090f0;
					box-shadow: 0px 4px 10px 0px rgba(0, 144, 240, 0.6);
					border-radius: 25px;
					text-align: center;
					color: #fff;
					font-size: 22px;
					font-weight: 700;
					cursor: pointer;
					letter-spacing: 5px;
				}

				.success {
					font-size: 16px;
					text-align: center;

					img {
						width: 50px;
						height: 50px;
						margin-top: 70px;
					}

					div:nth-child(2) {
						font-size: 22px;
						color: #09b34d;
						margin: 20px 0;
					}

					span {
						color: #f13232;
					}
				}
			}
		}

		.rooter {
			// margin-top: 100px;
			//   position: fixed;
			//   bottom: 0;
			//   left: 0;
			//   width: 100%;
		}
	}
</style>
<template>
	<div class="login_box">
		<div class="img_box"></div>
		<!-- <img class="login_bj" src="../../assets/img/login/img_0_1.png" alt="" /> -->
		<div class="login_box_box">
			<div class="login_container">
				<div class="back">
					<img @click="goBack" src="../../assets/img/login/back.png" alt="" />
				</div>
				<div class="login_left">
					<img :src="loginImg" alt="" />
				</div>
				<div class="login_fight">
					<!-- 登录 -->
					<div class="login_tit">{{ loginTit }}</div>
					<div v-show="loginActive == 0">
						<el-form :model="LG_from" :rules="LG_rules" ref="LG_ruleFrom" label-width="100px"
							class="demo-ruleForm">
							<el-form-item prop="LG_name">
								<img class="safe" src="../../assets/img/login/icon_zhanghao.png" alt="" />
								<el-input v-model="LG_from.LG_name" placeholder="请输入账号" clearable></el-input>
							</el-form-item>
							<el-form-item prop="LG_password">
								<img class="safe" src="../../assets/img/login/icon_mima.png" alt="" />
								<el-input v-model="LG_from.LG_password" placeholder="请输入您的密码" show-password
									autocomplete="off"></el-input>
							</el-form-item>
							<el-form-item class="yzm_box" prop="LG_yzm">
								<img class="safe" src="../../assets/img/login/icon_yzm.png" alt="" />
								<el-input v-model="LG_from.LG_yzm" placeholder="请输入验证码" autocomplete="off"></el-input>
								<div class="img_yz" @click="refreshCode">
									<Safe :identifyCode="identifyCode"></Safe>
								</div>
							</el-form-item>
							<div class="login_btm">
								<div>
									还没账号？<span @click="changeActive(1)"> 免费注册 </span>
								</div>
								<div @click="changeActive(2)">忘记密码</div>
							</div>
							<div class="login_btn" @click="submitForm('LG_ruleFrom', 0)">
								登录
							</div>
						</el-form>
					</div>
					<!-- 注册 -->
					<div v-show="loginActive == 1">
						<el-form :model="ZC_from" :rules="ZC_rules" ref="ZC_ruleForm" label-width="100px"
							class="demo-ruleForm">
							<el-form-item prop="ZC_phone">
								<img class="safe" src="../../assets/img/login/icon_zhanghao.png" alt="" />
								<el-input v-model="ZC_from.ZC_phone" placeholder="请输入账号" clearable></el-input>
							</el-form-item>
							<el-form-item class="yzm_box" prop="ZC_yzm">
								<img class="safe" src="../../assets/img/login/icon_yzm.png" alt="" />
								<el-input v-model="ZC_from.ZC_yzm" placeholder="请输入验证码"></el-input>
								<button :disabled="disabled2" class="yzm" @click="getYzm(0)">
									{{ btnTitle }}
								</button>
							</el-form-item>
							<el-form-item prop="ZC_pasd">
								<img class="safe" src="../../assets/img/login/icon_mima.png" alt="" />
								<el-input v-model="ZC_from.ZC_pasd" placeholder="请输入您的密码" show-password></el-input>
							</el-form-item>

							<div class="login_btm">
								<div>
									已有账号？
									<span @click="changeActive(0)"> 立即登录 </span>
								</div>
							</div>
							<div class="login_btn" @click="submitForm('ZC_ruleForm', 1)">
								注册
							</div>
						</el-form>
					</div>
					<!-- 找回密码 -->
					<div v-show="loginActive == 2">
						<el-form :model="FOR_from" :rules="FOR_rules" ref="FOR_ruleForm" label-width="100px"
							class="demo-ruleForm">
							<el-form-item prop="FOR_num">
								<img class="safe" src="../../assets/img/login/icon_zhanghao.png" alt="" />
								<el-input v-model="FOR_from.FOR_num" placeholder="请输入账号" clearable></el-input>
							</el-form-item>
							<el-form-item class="yzm_box" prop="FOR_yzm">
								<img class="safe" src="../../assets/img/login/icon_yzm.png" alt="" />
								<el-input v-model="FOR_from.FOR_yzm" placeholder="请输入验证码"></el-input>
								<button :disabled="disabled2" class="yzm" @click="getYzm(1)">
									{{ btnTitle }}
								</button>
							</el-form-item>
							<div class="login_btn" @click="submitForm('FOR_ruleForm', 2)">
								下一步
							</div>
						</el-form>
					</div>
					<!-- 新密码 -->
					<div v-show="loginActive == 3">
						<el-form :model="NEW_from" :rules="NEW_rules" ref="NEW_ruleForm" label-width="100px"
							class="demo-ruleForm">
							<el-form-item prop="NEW_pasd">
								<img class="safe" src="../../assets/img/login/icon_mima.png" alt="" />
								<el-input v-model="NEW_from.NEW_pasd" placeholder="请输入您的新密码" show-password></el-input>
							</el-form-item>
							<el-form-item prop="NEW_pasd2">
								<img class="safe" src="../../assets/img/login/icon_mima.png" alt="" />
								<el-input v-model="NEW_from.NEW_pasd2" placeholder="请再次输入您的新密码" show-password>
								</el-input>
							</el-form-item>
							<div class="login_btn" @click="submitForm('NEW_ruleForm', 3)">
								确定
							</div>
						</el-form>
					</div>
					<!-- 修改成功 -->
					<div v-show="loginActive == 4">
						<div class="success">
							<img src="../../assets/img/login/icon_success.png" alt="" />
							<div>密码重置成功!</div>
							<div>
								<span> {{ new_psd_time }}s </span>
								后返回登录页
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="rooter">
			<Footer></Footer>
		</div>
	</div>
</template>

<script>
	import Footer from "@/components/base/footer.vue";
	import Safe from "@/components/safe.vue";
	import {
		goToLogin,
		register,
		sendCode,
		ForgetPassd,
		setNewPsd,
		getLogo,
	} from "../../assets/js/user";
	export default {
		name: "Login",
		components: {
			Footer,
			Safe
		},
		data() {
			var checkPhone = (rule, value, callback) => {
				let reg = /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]/;
				if (reg.test(value.trim())) {
					return callback(new Error("请勿输入特殊字符"));
				} else {
					callback();
				}
			};
			var checkYZM = (rule, value, callback) => {
				if (value !== this.identifyCode) {
					return callback(new Error("请输入正确的验证码"));
				} else {
					callback();
				}
			};
			return {
				resFlag: 0,
				loginImg: "",
				id: "",
				// 0登录 1注册 2找回密码  3新密码 4修改成功
				loginActive: "",
				new_psd_time: "",
				loginTit: "登录",
				// 登录
				LG_from: {
					LG_name: "",
					LG_password: "",
					LG_yzm: "",
				},
				// 注册
				ZC_from: {
					ZC_phone: "",
					ZC_yzm: "",
					ZC_pasd: "",
				},
				// 忘记密码
				FOR_from: {
					FOR_num: "",
					FOR_yzm: "",
				},
				// 新密码
				NEW_from: {
					NEW_pasd: "",
					NEW_pasd2: "",
				},
				btnTitle: "获取验证码",
				time: "",
				disabled2: false,
				identifyCode: "",
				identifyCodes: "0123456789abcdwerwshdje", //随便打的
				LG_rules: {
					LG_name: [{
							required: true,
							message: "请输入账号",
							trigger: "blur"
						},
						// {
						//   pattern: /^1[3456789]\d{9}$/,
						//   message: "请输入正确的手机号码",
						//   trigger: "blur",
						// },
						// {
						//   min: 11,
						//   message: "长度最少为11个字符",
						//   trigger: "blur",
						// },
						{
							validator: checkPhone,
							trigger: "blur"
						},
					],
					LG_password: [{
							required: true,
							message: "请输入您的密码",
							trigger: "blur"
						},
						{
							min: 6,
							max: 10,
							message: "长度在 6 到 10 个字符",
							trigger: "blur",
						},
					],
					LG_yzm: [{
							required: true,
							message: "请输入验证码",
							trigger: "blur"
						},
						{
							min: 4,
							max: 4,
							message: "长度在4个字符",
							trigger: "blur"
						},
						{
							validator: checkYZM,
							trigger: "blur"
						},
					],
				},
				ZC_rules: {
					ZC_phone: [{
							required: true,
							message: "请输入账号",
							trigger: "blur"
						},
						{
							pattern: /^1[3456789]\d{9}$/,
							message: "请输入正确的手机号码",
							trigger: "blur",
						},
					],
					ZC_pasd: [{
							required: true,
							message: "请输入您的密码",
							trigger: "blur"
						},
						{
							min: 6,
							max: 10,
							message: "长度在 6 到 10 个字符",
							trigger: "blur",
						},
					],
					ZC_yzm: [{
							required: true,
							message: "请输入验证码",
							trigger: "blur"
						},
						{
							min: 6,
							max: 6,
							message: "长度在6个字符",
							trigger: "blur"
						},
					],
				},
				FOR_rules: {
					FOR_num: [{
							required: true,
							message: "请输入账号",
							trigger: "blur"
						},
						{
							pattern: /^1[3456789]\d{9}$/,
							message: "请输入正确的手机号码",
							trigger: "blur",
						},
					],
					FOR_yzm: [{
							required: true,
							message: "请输入验证码",
							trigger: "blur"
						},
						{
							min: 6,
							max: 6,
							message: "长度在6个字符",
							trigger: "blur"
						},
					],
				},
				NEW_rules: {
					NEW_pasd: [{
							required: true,
							message: "请输入您的新密码",
							trigger: "blur"
						},
						{
							min: 6,
							max: 6,
							message: "长度在6个字符",
							trigger: "blur"
						},
					],
					NEW_pasd2: [{
							required: true,
							message: "请再次输入您的新密码",
							trigger: "blur"
						},
						{
							min: 6,
							max: 6,
							message: "长度在6个字符",
							trigger: "blur"
						},
					],
				},
			};
		},
		mounted() {
			getLogo({
				siteId: window.localStorage.getItem("siteId"),
			}).then((res) => {
				// console.log(res);
				if (res.code == 200) {
					this.loginImg = res.data;
				}
			});
			this.loginActive = this.$route.query.active;
			this.loginTit = this.loginActive == 0 ? "登录" : "注册";
			this.refreshCode();
			// console.log(this.identifyCode);
		},
		methods: {
			goBack() {
				this.$router.push("/index");
			},
			getCode() {
				this.time = 60;
				this.timer = setInterval(() => {
					if (this.time == 0) {
						clearInterval(this.timer);
						this.btnTitle = "获取验证码";
						this.disabled2 = false;
					} else {
						this.btnTitle = this.time + "秒后重试";
						// this.btnTitle = "获取验证码" + "(" + this.time + ")";
						this.disabled2 = true;
						this.time--;
					}
				}, 1000);
			},
			submitForm(formName, i) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						// 登录
						if (i == 0) {
							// let reg = /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]/;
							// if (reg.test(this.LG_from.LG_name.trim())) {
							//   this.$message.error("请勿输入特殊字符");
							//   return;
							// }
							// if (this.LG_from.LG_yzm !== this.identifyCode) {
							//   this.$message.error("请输入正确的验证码！");
							//   return;
							// }
							goToLogin({
								username: this.LG_from.LG_name,
								password: this.LG_from.LG_password,
								siteId: window.localStorage.getItem("siteId"),
							}).then((res) => {
								// console.log(res);
								if (res.code == 200) {
									this.$message.success("登录成功！");
									window.localStorage.setItem(
										"userInfo",
										JSON.stringify(res.data.info)
									);
									window.sessionStorage.setItem(
										"U2",
										JSON.stringify(res.data.info)
									);
									// this.$router.push("/index");
									if (this.resFlag == 1) {
										this.$router.push("/index");
									} else {
										this.$router.go(-1);
									}
								} else {
									this.$message.error(res.msg);
									return;
								}
							});
						} else if (i == 1) {
							//注册
							register({
								username: this.ZC_from.ZC_phone,
								password: this.ZC_from.ZC_pasd,
								code: this.ZC_from.ZC_yzm,
								siteId: window.localStorage.getItem("siteId"),
							}).then((res) => {
								// console.log(res);
								if (res.code == 200) {
									this.resFlag = 1;
									this.$message.success("注册成功！快去登录吧！~");
									this.loginActive = 0;
									this.loginTit = "登录";
								} else {
									this.$message.error(res.msg);
									return;
								}
							});
						} else if (i == 2) {
							//忘记密码
							ForgetPassd({
								mobile: this.FOR_from.FOR_num,
								code: this.FOR_from.FOR_yzm,
								siteId: window.localStorage.getItem("siteId"),
							}).then((res) => {
								// console.log(res);
								if (res.code == 200) {
									this.$message.success("提交成功！");
									this.id = res.data.id;
									this.loginActive = 3;
								} else {
									this.$message.error(res.msg);
									return;
								}
							});
						} else if (i == 3) {
							// 重置密码
							if (this.NEW_from.NEW_pasd !== this.NEW_from.NEW_pasd2) {
								this.$message.error("两次密码输入不一致！");
								return;
							}
							setNewPsd({
								new_pass: this.NEW_from.NEW_pasd,
								repeat_pass: this.NEW_from.NEW_pasd2,
								id: this.id,
							}).then((res) => {
								// console.log(res);
								if (res.code == 200) {
									this.loginActive = 4;
									this.loginTit = "";
									this.new_psd_time = 3;
									this.timer2 = setInterval(() => {
										if (this.new_psd_time == 0) {
											clearInterval(this.timer2);
											this.loginActive = 0;
											this.loginTit = "登录";
										} else {
											this.new_psd_time--;
										}
									}, 1000);
								} else {
									this.$message.error(res.msg);
									return;
								}
							});
						}
					}
				});
			},
			// 获取验证码
			getYzm(i) {
				let mobile = "";
				if (i == 0) {
					// 注册
					mobile = this.ZC_from.ZC_phone;
				} else {
					//找回密码
					mobile = this.FOR_from.FOR_num;
				}
				if (!mobile) {
					this.$message.error("请先输入手机号！");
					return;
				}
				let reg = /^1[3456789]\d{9}$/;
				if (!reg.test(mobile)) {
					this.$message.error("请输入正确的手机号！");
					return;
				}
				sendCode({
					mobile: mobile,
					siteId: window.localStorage.getItem("siteId"),
				}).then((res) => {
					// console.log(res);
					if (res.code == 200) {
						this.$message.success(res.msg);
						this.getCode();
					} else {
						this.$message.error(res.msg);
						return;
					}
				});
			},
			// 点击免费注册
			changeActive(i) {
				this.loginActive = i;
				this.time = 0;
				this.LG_from = {};
				this.ZC_from = {};
				this.FOR_from = {};
				this.NEW_from = {};
				this.$refs["LG_ruleFrom"].resetFields();
				this.$refs["ZC_ruleForm"].resetFields();
				this.$refs["FOR_ruleForm"].resetFields();
				this.$refs["NEW_ruleForm"].resetFields();
				if (i == 0) {
					this.loginTit = "登录";
				} else if (i == 1) {
					this.loginTit = "注册";
				} else if (i == 2 || i == 3) {
					this.loginTit = "找回密码";
				}
			},
			refreshCode() {
				this.identifyCode = "";
				this.makeCode(this.identifyCodes, 4);
			},
			randomNum(min, max) {
				max = max + 1;
				return Math.floor(Math.random() * (max - min) + min);
			},
			// 随机生成验证码字符串
			makeCode(data, len) {
				for (let i = 0; i < len; i++) {
					this.identifyCode += data[this.randomNum(0, data.length - 1)];
				}
			},
		},
	};
</script>
